import { combineReducers } from 'redux'

import { LOGOUT_ADMIN } from '~/store/modules/auth/actions'
import api from '~/store/api'
import app from '~/store/modules/app/reducer'
import auth from '~/store/modules/auth/reducer'
import dashboard from '~/store/modules/dashboard/reducer'
import accounts from '~/store/modules/accounts/reducer'
import alerts from '~/store/modules/alerts/reducer'
import admins from '~/store/modules/admins/reducer'

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  app,
  auth,
  dashboard,
  accounts,
  alerts,
  admins,
})

// eslint-disable-next-line arrow-body-style
const rootReducer = (state, action) => {
  if (action.type === LOGOUT_ADMIN) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
