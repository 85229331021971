import { library } from '@fortawesome/fontawesome-svg-core'
// regular
import {
  faEnvelope as farEnvelope,
  faLock as farLock,
  faLockKeyhole as farLockKeyhole,
  faUnlock as farUnlock,
  faGaugeHigh as farGaugeHigh,
  faCircleQuestion as farCircleQuestion,
  faCalendarCheck as farCalendarCheck,
  faFlagCheckered as farFlagCheckered,
  faBriefcase as farBriefcase,
  faPiggyBank as farPiggyBank,
  faHouseHeart as farHouseHeart,
  faHouseUser as farHouseUser,
  faFileChartPie as farFileChartPie,
  faChessRookPiece as farChessRookPiece,
  faCreditCard as farCreditCard,
  faChartLineDown as farChartLineDown,
  faFileInvoice as farFileInvoice,
  faUser as farUser,
  faTrophy as farTrophy,
  faBell as farBell,
  faHouse as farHouse,
  faPhone as farPhone,
  faChartLineUp as farChartLineUp,
  faTriangleExclamation as farTriangleExclamation,
  faGift as farGift,
  faFaceGrin as farFaceGrin,
  faFileInvoiceDollar as farFileInvoiceDollar,
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faChevronDown as farChevronDown,
  faChevronUp as farChevronUp,
  faMagnifyingGlass as farMagnifyingGlass,
  faLink as farLink,
  faPlus as farPlus,
  faXmark as farXmark,
  faEllipsisVertical as farEllipsisVertical,
  faGridHorizontal as farGridHorizontal,
  faMessageLines as farMessageLines,
  faCar as farCar,
  faGraduationCap as farGraduationCap,
  faThumbsUp as farThumbsUp,
  faCirclePlus as farCirclePlus,
  faCircleMinus as farCircleMinus,
  faPrint as farPrint,
  faTrash as farTrash,
  faTrashCan as farTrashCan,
  faPen as farPen,
  faPencil as farPencil,
  faFileUser as farFileUser,
  faFileArrowUp as farFileArrowUp,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faCircleCheck as farCircleCheck,
  faCircleInfo as farCircleInfo,
  faCalendar as farCalendar,
  faNoteSticky as farNoteSticky,
  faKey as farKey,
  faSquarePhoneFlip as farSquarePhoneFlip,
  faCheck as farCheck,
  faFaucetDrip as farFaucetDrip,
  faPersonShelter as farPersonShelter,
  faHouseLaptop as farHouseLaptop,
  faBolt as farBolt,
  faFireFlameSimple as farFireFlameSimple,
  faDumbbell as farDumbbell,
  faShieldHeart as farShieldHeart,
  faBoxArchive as farBoxArchive,
  faTv as farTv,
  faCircleXmark as farCircleXmark,
  faMoneyCheckDollarPen as farMoneyCheckDollarPen,
  faLaptop as farLaptop,
  faCopy as farCopy,
  faCirclePause as farCirclePause,
  faHandHoldingBox as farHandHoldingBox,
  faGlobe as farGlobe,
  faLinkSlash as farLinkSlash,
  faLocationDot as farLocationDot,
  faCloudArrowUp as farCloudArrowUp,
  faSlashForward as farSlashForward,
  faDollarSign as farDollarSign,
  faAngleDown as farAngleDown,
  faLinkSimple as farLinkSimple
} from '@fortawesome/pro-regular-svg-icons'
// solid
import {
  faArrowRotateRight,
  faCaretDown,
  faMinus,
  faRocketLaunch,
  faCheck,
  faDisplay,
  faChevronLeft
} from '@fortawesome/pro-solid-svg-icons'
// brands
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  farEnvelope,
  farLock,
  farLockKeyhole,
  farUnlock,
  farGaugeHigh,
  farCircleQuestion,
  farCalendarCheck,
  farFlagCheckered,
  farBriefcase,
  farPiggyBank,
  farHouseHeart,
  farHouseUser,
  farFileChartPie,
  farChessRookPiece,
  farCreditCard,
  farChartLineDown,
  farFileInvoice,
  farUser,
  farTrophy,
  farBell,
  farHouse,
  farPhone,
  farChartLineUp,
  farTriangleExclamation,
  farGift,
  farFaceGrin,
  farFileInvoiceDollar,
  farArrowLeft,
  farArrowRight,
  farChevronLeft,
  farChevronRight,
  farMagnifyingGlass,
  farLink,
  farPlus,
  farXmark,
  farEllipsisVertical,
  farGridHorizontal,
  farMessageLines,
  farCar,
  farGraduationCap,
  farThumbsUp,
  farCirclePlus,
  farCircleMinus,
  farPrint,
  farTrash,
  farTrashCan,
  farPen,
  farPencil,
  farFileUser,
  farFileArrowUp,
  farEye,
  farEyeSlash,
  farCircleCheck,
  farCircleInfo,
  farCalendar,
  farNoteSticky,
  farKey,
  farSquarePhoneFlip,
  farCheck,
  farFaucetDrip,
  farPersonShelter,
  farHouseLaptop,
  farBolt,
  farFireFlameSimple,
  farDumbbell,
  farShieldHeart,
  farBoxArchive,
  farTv,
  farCircleXmark,
  farMoneyCheckDollarPen,
  farLaptop,
  farCopy,
  farCirclePause,
  farHandHoldingBox,
  farGlobe,
  farLinkSlash,
  farLocationDot,
  farCloudArrowUp,
  farSlashForward,
  farDollarSign,
  farChevronDown,
  farChevronUp,
  farChevronLeft,
  farChevronRight,
  farAngleDown,
  farLinkSimple,
  faArrowRotateRight,
  faCaretDown,
  faMinus,
  faRocketLaunch,
  faCheck,
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faDisplay,
  faChevronLeft
)
