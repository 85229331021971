/**
 * Generates the async action names for every request
 * @param {String} name
 */
export function generateActions(name) {
  return {
    REQUEST: name,
    SUCCESS: `${name}_SUCCESS`,
    FAIL: `${name}_FAIL`,
  }
}
