const getAlertEndpoints = builder => ({
  getAlertsList: builder.query({
    query: (params = {}) => {
      const {
        perPage,
        query,
        userUuid,
        page,
        sortField,
        sortDirection,
      } = params
      const currentpage = page || 1
      const data = { per_page: perPage || 4, page: currentpage, sort_field: sortField, sort_direction: sortDirection }
      const search = query || null
      const uid = userUuid || null

      if (search) data.q = search
      if (uid) data.uid = uid

      return {
        url: '/alerts',
        params: data,
      }
    },
    // providesTags: [USER_LINKED_BILLS_TAG_TYPE],
  }),
  getFilters: builder.query({
    query: () => ({
      url: '/alerts/filters',
    }),
  }),
})

export default getAlertEndpoints
