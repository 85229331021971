import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as SharedLinkComponent } from 'stellar-web-shared'

const Link = props => (
  <SharedLinkComponent
    LinkComponent={ReactRouterLink}
    {...props}
  />
)

export default Link
