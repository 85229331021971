import { PROMO_LIST_TAG_TYPE } from './constants'

const getPromoCodesEndpoints = builder => ({
  redeemPromoCode: builder.mutation({
    query: body => ({
      url: '/promo_codes/redeem',
      method: 'POST',
      body,
    })
  }),
  addPromoCode: builder.mutation({
    query: body => ({
      url: '/promo_codes',
      method: 'POST',
      body
    }),
    invalidatesTags: (_, error) => (error ? [] : [PROMO_LIST_TAG_TYPE]),
  }),
  updatePromoCode: builder.mutation({
    query: body => ({
      url: `/promo_codes/${body.uuid}`,
      method: 'PUT',
      body
    }),
    invalidatesTags: (_, error) => (error ? [] : [PROMO_LIST_TAG_TYPE]),
  }),
  getPromoCodesList: builder.query({
    query: (params = {}) => ({
      url: '/promo_codes',
      params
    }),
    providesTags: [PROMO_LIST_TAG_TYPE],
  }),
})

export default getPromoCodesEndpoints
