import { generateActions } from '~/utils'

export const LOAD_ADMINS_LIST = generateActions('admins/LOAD_ACCOUNT_LIST')
export const SHOW_ADMINS_MODAL = 'admins/SHOW_ADMINS_MODAL'
export const ADD_ADMIN = generateActions('admins/ADD_ADMIN')
export const SELECT_ADMIN = 'admins/SELECT_ADMIN'
export const UPDATE_ADMIN = generateActions('admins/UPDATE_ADMIN')
export const SET_ADMINS_TABLE_SETTING = 'admins/SET_ADMINS_TABLE_SETTING'

export function setAdminsModal(modal, show) {
  return {
    type: SHOW_ADMINS_MODAL,
    payload: { modal, show }
  }
}

export function selectAdmin(admin) {
  return ({
    type: SELECT_ADMIN,
    payload: { admin }
  })
}

export function setUsersTableSettings(settings) {
  return {
    type: SET_ADMINS_TABLE_SETTING,
    payload: { settings }
  }
}
