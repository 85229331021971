import { configureStore } from '@reduxjs/toolkit'
import reduxLogger from 'redux-logger'

import CONFIG from '~/config'
import rootReducer from '~/store/modules'
import api from '~/store/api'
import fetchMiddleware from '~/store/middlewares/fetchMiddleware'

const store = configureStore({
  reducer: rootReducer,
  // TODO: fetchMiddleware was temporary added to support old redux codebase, especially
  // the fetching logic (action creators were written in a specific manner instead of classic way)
  middleware: getDefaultMiddleware => [
    fetchMiddleware,
    ...getDefaultMiddleware().concat(api.middleware),
    ...(CONFIG.REDUX_LOGGER ? [reduxLogger] : []),
  ],
})

export default store
