export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  LOGIN_SAML: '/login-saml',
  LOGOUT: '/logout',
  RESET_PASSWORD: '/reset-password',
  APP: {
    ROOT: '/app',
    DASHBOARD: '/app/dashboard',
    ACCOUNTS: '/app/accounts',
    ACCOUNTS_DETAILS: '/app/accounts/:accountId/:menu',
    ALERTS: '/app/alerts',
    ADMINS: '/app/admins',
    PROMO_CODES: '/app/promo-codes',
    SEED_USERS: '/app/seed-users',
    SEARCH: '/app/search',
  }
}
