import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  formatCentsToDollars,
  formatDate,
  getTimeDiff,
  getDateTime,
} from '~/utils'

const SearchBarListItem = props => {
  const {
    item,
    onNavigate,
  } = props

  const handleNavigate = useCallback(() => onNavigate(item), [item, onNavigate])

  return (
    <div
      className="flex flex-col p-2 first:border-t border-b border-gray cursor-pointer hover:bg-gray"
      key={item.uuid}
      onClick={handleNavigate}
      role="button"
      tabIndex={0}
      aria-hidden="true"
    >
      <p>
        <span className="font-semibold mr-2 capitalize text-sm">{item.fullName}</span>
        <span className="text-sm">{item.email}</span>
      </p>
      <div className="flex flex-row items-center">
        <p className="text-xs">
          <span className="font-medium">
            Member since:
          </span>
          {' '}
          {formatDate(item.createdAt, 'FULL_DATE_TIME')}
          {' '}
          <span className="italic text-steel">
            {getTimeDiff(getDateTime(item.createdAt))}
          </span>
        </p>
        {item.lastSignInAt ? (
          <p className="text-xs ml-5">
            <span className="font-medium">
              Last login:
            </span>
            {' '}
            {formatDate(item.lastSignInAt, 'FULL_DATE_TIME')}
            {' '}
            <span className="italic text-steel">
              {getTimeDiff(getDateTime(item.lastSignInAt))}
            </span>
          </p>
        ) : null}
        <p className="text-xs ml-5">
          <span className="font-medium">
            30d spend:
          </span>
          {' '}
          {formatCentsToDollars(item.monthlyChargeableAmountCents)}
        </p>
        <p className="text-xs ml-5">
          <span className="font-medium">
            Account status:
          </span>
          {' '}
          {item.status}
        </p>
        {item.kycStatus && (
          <p className="text-xs ml-5">
            <span className="font-medium">
              KYC status:
            </span>
            {' '}
            {item.kycStatus}
          </p>
        )}
        {item.docvStatus && (
          <p className="text-xs ml-5">
            <span className="font-medium">
              DocV status:
            </span>
            {' '}
            {item.docvStatus}
          </p>
        )}
      </div>
    </div>
  )
}
SearchBarListItem.propTypes = {
  item: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    lastSignInAt: PropTypes.string,
    status: PropTypes.string.isRequired,
    kycStatus: PropTypes.string,
    docvStatus: PropTypes.string,
    monthlyChargeableAmountCents: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
  }).isRequired,
  onNavigate: PropTypes.func.isRequired,
}

const Memoized = React.memo(SearchBarListItem)
export default Memoized
