const config = {
  COOKIE_TOKEN: 'com.stellar.adminToken',
  ENVIRONMENT: process.env.NODE_ENV,
  HEROKU_APP_ENV: process.env.HEROKU_APP_ENV,
  HEROKU_SLUG_COMMIT: process.env.HEROKU_SLUG_COMMIT,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SERVER_URL: `${process.env.SERVER_API_URL}/${process.env.API_VERSION}`,
  CLIENT_APP_URL: process.env.CLIENT_APP_URL
}

export default config
