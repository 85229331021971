import { generateActions } from '~/utils'

export const LOAD_ACCOUNTS = generateActions('dashboard/LOAD_ACCOUNTS')
export const LOAD_RECENTLY_VIEWED = generateActions('dashboard/LOAD_RECENTLY_VIEWED')
export const LOAD_WIDGETS = generateActions('dashboard/LOAD_WIDGETS')
export const LOAD_ACCOUNTS_CHART = generateActions('dashboard/LOAD_ACCOUNTS_CHART')

export function loadAccounts(perPage) {
  const p = perPage || 4

  return ({
    types: LOAD_ACCOUNTS,
    promise: {
      url: '/accounts.json',
      method: 'GET',
      data: { per_page: p }
    }
  })
}

export function loadRecentlyViewed() {
  return ({
    types: LOAD_RECENTLY_VIEWED,
    promise: {
      url: '/dashboard/recently_viewed.json',
      method: 'GET',
    }
  })
}

export function loadWidgets() {
  return ({
    types: LOAD_WIDGETS,
    promise: {
      url: '/dashboard/bills_widgets.json',
      method: 'GET',
    }
  })
}

export function loadAccountsChart() {
  return ({
    types: LOAD_ACCOUNTS_CHART,
    promise: {
      url: '/dashboard/accounts_chart.json',
      method: 'GET',
    }
  })
}
