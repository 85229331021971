import { LOAD_ADMINS_LIST, SHOW_ADMINS_MODAL, SELECT_ADMIN, SET_ADMINS_TABLE_SETTING } from '~/store/modules/admins/actions'

const initialState = {
  admin: {
    uuid: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
  },
  admins: [],
  adminModals: {
    newAdmin: false
  },
  adminTableSettings: {
    loading: false,
    perPage: 25,
    currentPage: 1,
    sortField: undefined,
    sortDirection: undefined,
    totalRows: 0,
    totalPages: 0
  }
}

export default function admins(state = initialState, action) {
  switch (action.type) {
    case LOAD_ADMINS_LIST.SUCCESS:
      return {
        ...state,
        admins: action.payload.admins
      }
    case SHOW_ADMINS_MODAL:
      if (!action.payload.show) {
        return {
          ...state,
          adminModals: {
            ...state.adminModals,
            [action.payload.modal]: action.payload.show,
          },
          admin: {
            uuid: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirmation: '',
          }
        }
      }
      return {
        ...state,
        adminModals: {
          ...state.adminModals,
          [action.payload.modal]: action.payload.show,
        }
      }
    case SELECT_ADMIN:
      return {
        ...state,
        admin: action.payload.admin
      }
    case SET_ADMINS_TABLE_SETTING:
      return {
        ...state,
        adminSTableSettings: action.payload.settings
      }
    default:
      return state
  }
}
