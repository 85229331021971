export const numberFormat = (num, fallback = '0') => {
  if (!num) return fallback
  return num.toLocaleString('en-US')
}

export const currencyFormat = (num, fallback = '$0') => {
  if (!num) return fallback
  return `$${Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
}

export const formatCentsToDollars = (cents, options = {}) => {
  const dollars = cents / 100
  const styleOptions = {
    style: 'currency',
    currency: 'USD',
    ...options,
  }
  return dollars.toLocaleString('en-US', styleOptions)
}

const NON_DIGITS = /[^\d]/g

export const formatCentsToDollarString = val => {
  const dollarString = String(val)

  if (dollarString.length < 3) {
    return dollarString.length < 2 ? `0.0${dollarString}` : `0.${dollarString}`
  }

  const cents = dollarString.slice(-2)
  const dollars = dollarString.slice(0, -2)

  return `${dollars}.${cents}`
}

export const formatTypedStringToDollarString = val => {
  let value = String(val).replace(NON_DIGITS, '')

  while (value.length < 3) {
    value = `0${value}`
  }

  value = `${value.slice(0, -2)}.${value.slice(-2)}`
  return parseFloat(value).toFixed(2)
}

export const formatDollarsToCents = val => Math.round(+val * 100)
