import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'

import { ROUTES } from '~/constants'
import { isAdminAuthenticated } from '~/utils'

const NonAuthRoute = props => {
  const { children } = props
  const location = useLocation()

  const isAuth = isAdminAuthenticated()

  if (isAuth) {
    return <Navigate to={ROUTES.APP.DASHBOARD} state={{ from: location }} replace />
  }
  if (location.pathname === ROUTES.ROOT) {
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />
  }

  return children
}
NonAuthRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(NonAuthRoute)
