// Adding the REST methods to Connection object
const methods = ['delete', 'get', 'post', 'put']

export default class Connection {
  // default option values
  defaults = {
    host: '/',
    method: 'GET',
    timeout: 3600000,
    onProgress: undefined,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  // The access token
  token = ''

  constructor(config = {}) {
    methods.forEach((method) => {
      this[method] = (options) => {
        const opts = {
          ...config,
          ...options,
          method: method.toUpperCase(),
        }

        return this.fetchCall(opts)
      }
    })
  }

  fetchCall(options) {
    // Assign the authorization token if available
    const result = {
      ...this.defaults,
      ...options,
      headers: {
        ...this.defaults.headers,
        ...options.headers,
      },
    }

    if (this.token) {
      result.headers.Authorization = `Bearer ${this.token}`
    } else {
      delete result.headers.Authorization
    }

    // Stringify params
    if (result.method !== 'POST' && result.method !== 'PUT') {
      if (result.method === 'GET' && result.data) {
        const params = Object.keys(result.data).filter(name => result.data[name] !== undefined).map(name => `${name}=${result.data[name]}`)
        result.url = `${options.url}${params.length > 0 ? `?${params.join('&')}` : ''}`
      } else {
        result.params = JSON.stringify(result.data)
      }
    } else if (result.form) {
      const form = new FormData()

      Object.keys(result.form).forEach((key) => {
        if (result.form[key] !== undefined) {
          form.append(key, result.form[key])
        }
      })

      delete result.form
      delete result.data
      delete result.headers['Content-Type']

      result.body = form
    } else {
      result.body = JSON.stringify(result.data)
    }

    return new Promise((resolve, reject) => {
      const conn = new XMLHttpRequest()
      conn.addEventListener('load', () => {
        let json
        try {
          json = JSON.parse(conn.responseText)
        } catch (error) {
          // In case the response is not JSON
          json = {
            statusCode: conn.status,
            ...error,
          }
        }

        if (conn.status >= 400) {
          reject({
            data: json,
            endpoint: conn.url,
            status: conn.status,
          })
        }

        resolve(json)
      })

      conn.addEventListener('error', (error) => {
        reject({ data: { errors: ['No internet connection!'] }})
      })

      if (result.onProgress) {
        conn.upload.addEventListener('progress', (event) => {
          if (event.lengthComputable) {
            const percent = event.loaded / event.total * 100
            result.onProgress({
              loaded: event.loaded,
              total: event.total,
              progress: Math.ceil(percent),
            })
          }
        })
      }

      conn.open(result.method, `${result.host}${result.url}`)

      // Settign headers
      if (result.headers) {
        Object.keys(result.headers).forEach((header) => {
          conn.setRequestHeader(header, result.headers[header])
        })
      }

      // Setting the time out
      conn.timeout = result.timeout

      conn.send(result.body)
    })
  }

  getToken() {
    return this.token
  }

  setToken(tkn) {
    this.token = tkn
  }

  clearToken() {
    this.token = undefined
  }
}
