const getSearchEndpoints = builder => ({
  fetchSearch: builder.query({
    query: (params = {}) => ({
      url: '/search',
      params,
    }),
  }),
})

export default getSearchEndpoints
