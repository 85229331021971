import {
  LOAD_ALERTS,
  SET_ALERT,
  SET_SHOW_MODAL,
  SET_ALERT_VALUE,
  SET_SEARCH_VALUE,
  SET_USER_UUID,
  SET_ALERT_TABLE_SETTING,
  SET_CURRENT_PAGE,
} from '~/store/modules/alerts/actions'

const initialState = {
  alerts: [],
  selectedAlert: {},
  showSideBar: false,
  searchValue: '',
  currentPage: 1,
  uid: '',
  alertTableSettings: {
    loading: false,
    perPage: 25,
    currentPage: 1,
    sortField: undefined,
    sortDirection: undefined,
    totalRows: 0,
    totalPages: 0
  }
}

export default function alerts(state = initialState, action) {
  switch (action.type) {
    case LOAD_ALERTS.SUCCESS:
      return {
        ...state,
        alerts: action.payload.alerts,
      }
    case SET_ALERT:
      return {
        ...state,
        selectedAlert: action.payload.alert,
        showSideBar: true
      }
    case SET_SHOW_MODAL:
      return {
        ...state,
        showSideBar: action.payload.value,
      }
    case SET_ALERT_VALUE:
      return {
        ...state,
        selectedAlert: {
          ...state.selectedAlert,
          [action.payload.field]: action.payload.value
        },
      }
    case SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload.value
      }
    case SET_USER_UUID:
      return {
        ...state,
        userUuid: action.payload.value
      }
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload.value
      }
    case SET_ALERT_TABLE_SETTING:
      return {
        ...state,
        alertTableSettings: action.payload.settings
      }
    default:
      return state
  }
}
