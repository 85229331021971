const getHighnoteQaEndpoints = builder => ({
  simulateHighnoteAuth: builder.mutation({
    query: ({ accountId, amount, description, scenario, merchantId }) => {
      const formData = new FormData()
      formData.append('user_uuid', accountId)
      formData.append('amount', amount)
      formData.append('description', description)
      formData.append('scenario', scenario)
      formData.append('merchant_id', merchantId)

      return {
        url: '/qa/highnote/simulate_bill_payment',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        body: formData,
      }
    },
  }),
})

export default getHighnoteQaEndpoints
