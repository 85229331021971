export const PERMISSIONS = {
  ARCHIVE_BANK_ACCOUNT: 'archive_bank_account',
  CLOSE_ACCOUNT: 'close_account',
  CREATE_REFUND: 'create_refund',
  DISPUTE_TRANSACTIONS: 'dispute_transactions',
  FORCE_CLOSE_ACCOUNT: 'force_close_account',
  FORGET_USER: 'forget_user',
  IMPERSONATE_USER: 'impersonate_user',
  ISSUE_BANKRUPTCY: 'issue_bankruptcy',
  LOCK_ACCOUNT: 'lock_account',
  MANUALLY_VERIFY_USER: 'manually_verify_user',
  OPEN_USER_DOCS: 'open_user_docs',
  OVERRIDE_SPEND_LIMIT: 'override_spend_limit',
  PAUSE_BILLING_CARD: 'pause_billing_card',
  REFUND_OUTSTANDING_BALANCE: 'refund_outstanding_balance',
  REFUND_OVER_COLLECTION_AMOUNT: 'refund_over_collection_amount',
  REFUND_SUBSCRIPTION_PLAN_BILL: 'refund_subscription_plan_bill',
  REPLACE_BILLING_CARD: 'replace_billing_card',
  RESET_DOCUMENT_VERIFICATION: 'reset_document_verification',
  RETRY_CREDIT_SERVICE_REGISTRATION: 'retry_credit_service_registration',
  UNLOCK_ACCOUNT: 'unlock_account',
  UNPAUSE_BILLING_CARD: 'unpause_billing_card',
  UPDATE_PROMO_CODES: 'update_promo_codes',
  VIEW_ADMIN_LIST: 'view_admin_list',
  VIEW_CREDIT_CARD_NUMBER: 'view_credit_card_number',
  VIEW_SSN: 'view_ssn',
  WRITE_OFF_SUBSCRIPTION_PLAN_BILL: 'write_off_subscription_plan_bill',
  CHANGE_FRAUD_TYPE: 'change_fraud_type',
  UNLOCK_BANK: 'unlock_bank',
}
