import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Card = props => {
  const {
    children,
    className,
    padding,
    style,
    id,
  } = props
  return (
    <div
      id={id}
      style={style}
      className={classNames('rounded-xl bg-white shadow', { 'p-5': padding }, className)}
    >
      {children}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  padding: PropTypes.bool,
  style: PropTypes.shape({}),
  id: PropTypes.string,
}
Card.defaultProps = {
  children: null,
  className: '',
  padding: true,
  style: {},
  id: '',
}

const Memoized = React.memo(Card)
export default Memoized
