import { useFetchAdminQuery } from '~/store/api'
import {
  isAdminAuthenticated,
  checkPermissions,
} from '~/utils'

export const useCan = (permissions, any = false) => {
  const isAuth = isAdminAuthenticated()
  const {
    data = {},
  } = useFetchAdminQuery(undefined, { skip: !isAuth })
  const permissionList = data?.permissions?.reduce((ac, permission) => ({ ...ac, [permission]: true }), {})
  const adminPermissions = {
    ...permissionList
  }

  return checkPermissions(adminPermissions, permissions, any)
}
