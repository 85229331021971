const initialState = {
  /**
   * A map to track all the request calls.
   */
  requests: {},
}

export default function reducer(original = initialState, action) {
  let state = original

  if (action.requestId) {
    state = {
      ...original,
      requests: {
        ...original.requests,
      },
    }

    if (state.requests[action.requestId]) {
      delete state.requests[action.requestId]
    } else {
      state.requests[action.requestId] = true
    }
  }

  return state
}
