import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import NiceModal from '@ebay/nice-modal-react'

import config from '~/config'
import store from '~/store'
import '~/icons'
import '~/theme/index.css'
import ErrorBoundary from '~/containers/ErrorBoundary'
import App from '~/containers/App'

import reportWebVitals from './reportWebVitals'

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    release: config.HEROKU_SLUG_COMMIT || `v${process.env.npm_package_version}`,
    environment: config.HEROKU_APP_ENV || config.ENVIRONMENT,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.2,
  })
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <NiceModal.Provider>
            <App />
          </NiceModal.Provider>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
