import React, { useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'

import SiteTopbar from '~/containers/Layout/SiteTopbar'

const mappings = _ => ({ })

const Site = () => {
  const location = useLocation()

  const hasBg = useMemo(() => {
    if (location.pathname.split('/').includes('reset-password')) return true
    if (['/', '/login', '/request-password', '/subscription'].includes(location.pathname)) return true
    return false
  }, [location.pathname])

  const hasHeader = useMemo(() => location.pathname !== '/welcome', [location.pathname])

  return (
    <div className={classNames({ 'landing text-default': hasBg, 'w-screen': !hasBg, 'stars-landing text-default': hasHeader })}>
      { hasHeader && <SiteTopbar hasBg={hasBg} /> }
      <Outlet />
    </div>
  )
}

export default connect(mappings, { })(Site)
