import React from 'react'
import ReactModal from 'react-modal'

import Pages from '~/pages'

ReactModal.setAppElement('#root')

export default function App() {
  return <Pages />
}
