import {
  loadCurrentAdmin,
  setToken
} from '~/store/modules/auth/actions'

import Config from '~/config'
import { getToken } from '~/store/modules/auth/selectors'

/**
 * Bootstrap the APP!! All initial requirements
 *
 * - Checks if there's a token in local storage and
 * then loads the user's information.
 */
export function bootstrap(navigate) {
  return (dispatch, getStore) => {
    let token = getToken(getStore())
    if (token === '') {
      token = localStorage.getItem(Config.COOKIE_TOKEN)
    }

    if (token) {
      dispatch(setToken(token))
      dispatch(loadCurrentAdmin()).then(response => {
        localStorage.setItem(Config.COOKIE_TOKEN, response.payload.jwt_token)
      }).catch(() => {
        navigate('/logout')
      })
    } else {
      navigate('/login')
    }
  }
}
