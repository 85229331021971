import { generateActions } from '~/utils'

export const LOAD_ALERTS = generateActions('alerts/LOAD_ALERTS')
export const SET_ALERT = 'alerts/SET_ALERT'
export const SET_SHOW_MODAL = 'alerts/SET_SHOW_MODAL'
export const SET_ALERT_VALUE = 'alerts/SET_ALERT_VALUE'
export const SET_SEARCH_VALUE = 'alerts/SET_SEARCH_VALUE'
export const SET_USER_UUID = 'alerts/SET_USER_UUID'
export const SET_ALERT_TABLE_SETTING = 'alerts/SET_ALERT_TABLE_SETTING'
export const SET_CURRENT_PAGE = 'alerts/SET_CURRENT_PAGE'

export function loadAlerts(perPage, query, page, sortField, sortDirection, userUuid) {
  const currentpage = page || 1
  const data = { per_page: perPage || 4, page: currentpage, sort_field: sortField, sort_direction: sortDirection }
  const search = query || null
  const uid = userUuid || null

  if (search) data.q = search
  if (uid) data.uid = uid

  return ({
    types: LOAD_ALERTS,
    promise: {
      url: '/alerts.json',
      method: 'GET',
      data
    }
  })
}

export function setAlert(alert) {
  return {
    type: SET_ALERT,
    payload: { alert }
  }
}

export function setShowModal(value) {
  return {
    type: SET_SHOW_MODAL,
    payload: { value }
  }
}

export function setAlertValue(field, value) {
  return {
    type: SET_ALERT_VALUE,
    payload: { field, value }
  }
}

export function setSearchValue(value) {
  return {
    type: SET_SEARCH_VALUE,
    payload: { value }
  }
}

export function setCurrentPage(value) {
  return {
    type: SET_CURRENT_PAGE,
    payload: { value }
  }
}
