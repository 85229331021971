import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Item from './Item'

const SearchBarResults = props => {
  const {
    results,
    isFetching,
    onCloseResults,
    onNavigate,
    within,
    errorMessage,
  } = props

  const {
    recentlyViewed = [],
    users = [],
  } = results

  return (
    <div className={`${within ? '' : 'absolute mt-16 top-0 left-0 right-0 bottom-0 z-50 bg-white shadow'}  text-black overflow-scroll`}>
      {isFetching ? (
        <div className="flex justify-center">
          <div className="spinner m-20" role="status" label="spinner" />
        </div>
      ) : (
        <>
          {errorMessage ? (
            <p className="text-red text-sm mt-2">
              API error:
              <span className="ml-2">
                {errorMessage}
              </span>
            </p>
          ) : (
            <div className="container mx-auto px-2">
              <div className="pt-2 pb-4">
                {!within ? (
                  <button
                    type="button"
                    className="p-2 ml-auto block"
                    onClick={onCloseResults}
                  >
                    <FontAwesomeIcon
                      icon="far fa-circle-xmark"
                      size="lg"
                    />
                  </button>
                ) : null}
                {!recentlyViewed.length && !users.length ? (
                  <p className="text-xl text-center">
                    There are no records to display
                  </p>
                ) : (
                  <>
                    {recentlyViewed.length ? (
                      <>
                        <h5 className="my-3 px-2">
                          Recently Viewed
                        </h5>
                        <div className="mb-6">
                          {recentlyViewed.map(recentlyViewedUser => (
                            <Item
                              key={recentlyViewedUser.uuid}
                              item={recentlyViewedUser}
                              onNavigate={onNavigate}
                            />
                          ))}
                        </div>
                      </>
                    ) : null}
                    {users.length ? (
                      <>
                        <h5 className="my-3 px-2">
                          {recentlyViewed.length ? 'Other members' : 'Members'}
                        </h5>
                        <div className="mb-6">
                          {users.map(user => (
                            <Item
                              key={user.uuid}
                              item={user}
                              onNavigate={onNavigate}
                            />
                          ))}
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
SearchBarResults.propTypes = {
  results: PropTypes.shape({
    recentlyViewed: PropTypes.arrayOf(PropTypes.shape({})),
    users: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onCloseResults: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  within: PropTypes.bool,
  errorMessage: PropTypes.string,
}
SearchBarResults.defaultProps = {
  within: false,
  errorMessage: '',
}

const Memoized = React.memo(SearchBarResults)
export default Memoized
